<template>
  <b-form-group
    label="Açıklama"
    :label-for="'product_content_' + itemKey"
  >
    <b-form-input
      :id="'product_content_' + itemKey"
      v-model="dataItem.proforma_lines[itemKey].content"
      placeholder="Açıklama"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'ProductTitle',
  components: {
    BFormGroup,
    BFormInput,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['proformaInvoices/dataItem']
    },
  },
}
</script>
