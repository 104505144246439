<template>
  <b-form-group
    label="Proforma No"
    label-for="pno"
  >
    <b-form-input
      id="pno"
      v-model="dataItem.pno"
      placeholder="Proforma No"
      readonly
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'ProformaNo',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    dataItem() {
      return this.$store.getters['proformaInvoices/dataItem']
    },
  },
}
</script>
