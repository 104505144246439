<template>
  <b-form-group
    label="Ürün Adı"
    :label-for="'productTitle_' + itemKey"
  >
    <validation-provider
      #default="{ errors }"
      name="Ürün Adı"
      rules="required"
    >
      <b-form-input
        :id="'productTitle_' + itemKey"
        v-model="dataItem.proforma_lines[itemKey].title"
        placeholder="Ürün Adı"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'ProductTitle',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['proformaInvoices/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
