<template>
  <b-form-group
    label="İlgili Marka"
    label-for="id_brands"
  >
    <validation-provider
      #default="{ errors }"
      name="İlgili Marka"
      rules="required"
    >
      <v-select
        id="id_brands"
        v-model="dataItem.id_brands"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['proformaInvoices/dataItem']
    },
    dataList() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData.brands
    },
  },
  created() {
    localize('tr')
  },
}
</script>
