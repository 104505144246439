<template>
  <div class="bg-light-primary p-1 rounded">
    <b-row class="d-flex align-items-center">
      <b-col>
        <div class="font-weight-light">
          Toplam Tutar <span class="font-small-2 text-primary">(KDV Dahil)</span>
        </div>
        <div
          class="font-medium-3 font-weight-bolder"
        >
          {{ dataItem.proforma_lines[itemKey].total_price | toCurrency }}
          {{ dataItem.proforma_lines[0].currency }}
        </div>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="flat-danger"
          class="btn-icon"
          pill
          @click="removeItem(itemKey)"
        >
          <FeatherIcon icon="TrashIcon" /> Sil
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'TotalPrice',
  components: {
    BRow,
    BCol,
    BButton,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['proformaInvoices/dataItem']
    },
    saveLineData() {
      return this.$store.getters['proformaInvoices/dataLineSaveStatus']
    },
  },
  watch: {
    saveLineData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  methods: {
    removeItem(index) {
      if (this.dataItem.proforma_lines[index].id) {
        this.$store.dispatch('proformaInvoices/removeLine', this.dataItem.proforma_lines[index].id)
      }
      this.dataItem.proforma_lines.splice(index, 1)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
<style scoped>
.strikethrough{
  text-decoration: line-through !important;
}
</style>
