<template>
  <div>
    <b-card title="Proforma Bilgileri">
      <b-row>
        <b-col>
          <proforma-date />
        </b-col>
        <b-col>
          <proforma-no />
        </b-col>
        <b-col cols="12">
          <brands />
        </b-col>
        <b-col cols="12">
          <payment-type />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <delivery-location />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <delivery-type />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <origin />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <deadline />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <gtip />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <myear />
        </b-col>
        <b-col
          cols="12"
        >
          <bank-accounts />
        </b-col>
        <b-col cols="12">
          <notes />
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Ürünler</b-card-title>
      </b-card-header>
      <b-card-body>
        <products v-if="dataItem.id_brands" />
        <b-alert
          v-else
          variant="warning"
          show
        >
          <div class="alert-body text-center">
            Ürün eklemek için önce marka seçimi yapın.
          </div>
        </b-alert>
      </b-card-body>
      <b-card-footer v-if="dataItem.proforma_lines.length > 0">
        <grand-total />
      </b-card-footer>
    </b-card>
    <save-button
      :save-action="submitForm"
      :is-disabled="submitStatus"
    />
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BCardFooter, BAlert,
} from 'bootstrap-vue'
import ProformaDate from '@/views/Admin/Proforma_invoices/elements/ProformaDate.vue'
import ProformaNo from '@/views/Admin/Proforma_invoices/elements/ProformaNo.vue'
import Brands from '@/views/Admin/Proforma_invoices/elements/Brands.vue'
import PaymentType from '@/views/Admin/Proforma_invoices/elements/PaymentType.vue'
import Notes from '@/views/Admin/Proforma_invoices/elements/Notes.vue'
import DeliveryLocation from '@/views/Admin/Proforma_invoices/elements/DeliveryLocation.vue'
import DeliveryType from '@/views/Admin/Proforma_invoices/elements/DeliveryType.vue'
import Origin from '@/views/Admin/Proforma_invoices/elements/Origin.vue'
import Deadline from '@/views/Admin/Proforma_invoices/elements/Deadline.vue'
import Gtip from '@/views/Admin/Proforma_invoices/elements/Gtip.vue'
import Myear from '@/views/Admin/Proforma_invoices/elements/Myear.vue'
import BankAccounts from '@/views/Admin/Proforma_invoices/elements/BankAccounts.vue'
import Products from '@/views/Admin/Proforma_invoices/elements/Products.vue'
import GrandTotal from '@/views/Admin/Proforma_invoices/elements/GrandTotal.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'

export default {
  name: 'ProformaForm',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardFooter,
    BAlert,
    ProformaDate,
    ProformaNo,
    Brands,
    PaymentType,
    DeliveryLocation,
    DeliveryType,
    Origin,
    Deadline,
    Gtip,
    Myear,
    BankAccounts,
    Notes,
    Products,
    GrandTotal,
    SaveButton,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
    submitStatus: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['proformaInvoices/dataItem']
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
