<template>
  <b-form-group
    label="Banka Hesabı"
    label-for="id_bank_accounts"
  >
    <validation-provider
      #default="{ errors }"
      name="Banka Hesabı"
      rules="required"
    >
      <v-select
        id="id_bank_accounts"
        v-model="dataItem.id_bank_accounts"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      >
        <template v-slot:option="option">
          <div class="font-weight-bold">
            {{ option.title }}
          </div>
          <div class="text-muted font-small-2">
            {{ option.bank }}
          </div>
          <div class="text-muted font-small-2">
            {{ option.iban }}
          </div>
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'OfferStatus',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['proformaInvoices/dataItem']
    },
    dataList() {
      return this.$store.getters['bankAccounts/dataList']
    },
  },
  created() {
    this.getData()
    localize('tr')
  },
  methods: {
    getData() {
      this.$store.dispatch('bankAccounts/getDataList', {
        select: [
          'bank_accounts.id AS id',
          'banks.title AS bank',
          'bank_accounts.title AS title',
          'bank_accounts.iban AS iban',
          'currencies.symbol AS currency',
        ],
      })
    },
  },
}
</script>
