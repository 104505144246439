<template>
  <div>
    <b-form-group
      label="Notlar"
      label-for="notes"
    >
      <b-form-textarea
        id="notes"
        v-model="dataItem.notes"
        placeholder="Notlar"
      />
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'Notes',
  components: {
    BFormGroup,
    BFormTextarea,
  },
  computed: {
    dataItem() {
      return this.$store.getters['proformaInvoices/dataItem']
    },
  },
}
</script>
